import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App";
import router from "./router";
import VueScreenSize from 'vue-screen-size';
import VueSlickCarousel from 'vue-slick-carousel'
import Vue2TouchEvents from "vue2-touch-events";



import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.use(Vue2TouchEvents);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueScreenSize);
Vue.use(VueSlickCarousel);

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");